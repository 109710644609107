* {
	box-sizing: border-box;
}

html,
body,
#root {
	margin: 0;
	padding: 0;
	height: 100vh;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root {
	display: grid;
	grid-template-rows: 1fr auto;
}
