.color {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 2.5em;
}

.container {
	display: flex;
	padding: 4em 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 1.4em;
}

button {
	margin: 0.6em 0;
	font-size: 1em;
}
